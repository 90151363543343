// PlantTabs.js
import React, { useState } from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

export const Plants = ({ plantData }) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="plantstab">
      <div className="container">
        <div className="row">
          <div className="web-container">
            <div className="title">
              <h2>Plants & Offices</h2>
            </div>
            <Tabs defaultIndex={0} onSelect={(index) => setActiveTab(index)}>
              <TabList>
                {plantData.map((plant, index) => (
                  <Tab key={plant.region}>{plant.region}</Tab>
                ))}
              </TabList>

              {plantData.map((plant, index) => (
                <TabPanel key={plant.region}>
                  <div className="plantbox">
                    {plant.locations.map((location, locIndex) => (
                      <div key={locIndex} className="adrssblk">
                        <h4>{location.name}</h4>
                        <p>{location.address}</p>
                        <p>
                          <strong>Sales : </strong>
                          <a
                            href={`tel:${formatPhoneNumber(
                              location.salesContact
                            )}`}
                          >
                            {location.salesContact}
                          </a>
                        </p>
                        {location.productionContact && (
                          <p>
                            <strong>Production : </strong>
                            <a
                              href={`tel:${formatPhoneNumber(
                                location.productionContact
                              )}`}
                            >
                              {location.productionContact}
                            </a>
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export const formatPhoneNumber = (phoneNumber) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the number starts with +91 or 91
  const numberWithoutCountryCode = cleaned.replace(/^(\+?91|91)/, "");

  // Format the number in the Indian phone number format
  const formattedNumber = numberWithoutCountryCode.replace(
    /(\d{5})(\d{5})/,
    "$1 $2"
  );

  return formattedNumber;
};
